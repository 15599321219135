import React from 'react';

import dataDetail from '@data/productDetail.json';
import {Collapse} from 'antd';
const {Panel} = Collapse;
import Icon from '@common/Icon';
import TitleCollapse from './TitleCollapse';
import colors from '@colors';

const detailedInformation = () => {
  const dataProductDetail = dataDetail.data;
  return (
    <>
      {dataProductDetail.detailedInformation.map((item, index) => {
        return (
          <div className="detailed-information" key={index}>
            <Collapse
              className="collapse-detail"
              defaultActiveKey={['open']}
              expandIcon={({isActive}) =>
                isActive ? (
                  <Icon className="minus" icon="minus" size={24} color={colors.black} />
                ) : (
                  <Icon className="minus" icon="plus" size={24} color={colors.black} />
                )
              }
              expandIconPosition="end"
              ghost>
              <Panel header={<TitleCollapse title={item.title} />} key="open">
                <div className="content">
                  <div className="list">
                    {item.list.map((item1, index1) => {
                      return (
                        <ul key={index1}>
                          <li>{item1.name}</li>
                        </ul>
                      );
                    })}
                  </div>
                </div>
                <div className="discover-more">
                  <span>Khám phá thêm</span>
                  <Icon className="arow-right" icon="arrow-right-line" size={12} color={colors.darkGrey} />
                </div>
              </Panel>
            </Collapse>
          </div>
        );
      })}
    </>
  );
};

export default detailedInformation;
