export const UPDATE_APP_INFO_STATE = 'UPDATE_APP_INFO_STATE';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const RESET_APP_STATE = 'RESET_APP_STATE';

export const resetAppState = () => ({
  type: RESET_APP_STATE
});

export const updateAppInfoState = app_state => ({
  type: UPDATE_APP_INFO_STATE,
  data: {
    app_state: app_state
  }
});

export const updateLanguage = lang => ({
  type: UPDATE_LANGUAGE,
  data: {
    lang: lang
  }
});
