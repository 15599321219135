import React, { useState } from 'react';
import Icon from "@icon";
import Section from '../../common/Section';

const Subscribe = () => {
  const [importValue, setImportValue] = useState("");

  return (
    <div className="email-signup">
      <Section container="small">
        <div className="txt-register">Đăng ký email</div>
        <div className="content-register">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia</div>
        <div className="subcribe-wrapper">
          <input
            type="text"
            name="search-header"
            value={importValue}
            onChange={(e) => {
              setImportValue(e.target.value);
            }}
            placeholder="Nhập Email"
          />
          <button className="btn-primary --bg-black">
            <span>Đăng ký</span>
            <Icon className="arrow-right" icon="arrow-right-bold" size={18} color="black" />
          </button>
        </div>
      </Section>
    </div>
  )
}

export default Subscribe;
