import React, { useState } from 'react';
import Icon from '@icon';
import { Link } from 'react-router-dom';
import logosaxgc from '@images/header/logo-axgc.svg';
import { Dropdown } from 'antd';

const itemsHelp = [
  { label: <a href="/about">Gift cards</a> },
  { label: <a href="/about">Bảo mật thông tin</a> },
  { label: <a href="/about">Tra cứu đơn hàng</a> },
  { label: <a href="/about">Tìm cửa hàng</a> },
  { label: <a href="/about">Đăng ký thành viên</a> },
  { label: <a href="/about">Gửi feedback</a> }
];

const Topbar = () => {
  const [activeDropdown, setActiveDropdown] = useState(false);

  return (
    <div className="top-bar-header">
      <div className="container --no-padding">
        <div className="sub-menu">
          <div className="logo-brand-line">
            <Link to="/">
              <img src={logosaxgc} />
            </Link>
          </div>
          <div className="sub-list-menu">
            <ul>
              <li>
                <Link to="/about"><span>Tìm Cửa Hàng</span></Link>
              </li>
              <li>
                <Icon
                  className="icon-rhombus"
                  icon="dot-diamond"
                  size={8}
                  color="black"
                />
                <Link to="/about"><span>Tra Cứu Đơn Hàng</span></Link>
              </li>
              <li className={activeDropdown ? 'active' : ''}>
                <Icon
                  className="icon-rhombus"
                  icon="dot-diamond"
                  size={8}
                  color="black"
                />
                <Dropdown
                  className="dropdown-select"
                  overlayClassName="dropdown-select"
                  placement="bottomRight"
                  menu={{ items: itemsHelp, selectable: true}}
                  trigger={['click']}
                  onOpenChange={(open) => {
                    setActiveDropdown(open);
                  }}
                  getPopupContainer={() => document.querySelector('.simplebar-content')}
                >
                  <span>
                    <span className="label-dropdown">Trợ Giúp</span>
                    <Icon className="icon-arrow-up" icon={activeDropdown ? "arrow-up" : "arrow-down"} size={24} color="black" />
                  </span>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
