import React, {useState} from 'react';
import PopupData from '@data/popup.json';
import PopupSizeChart from '../PopupSizeChart';
import {arrModSizeChart, priceSplitter} from '@configs/utils/helpers';
import Icon from '@common/Icon';

const RightPopup = () => {
  const dataProductDetail = PopupData.data;
  const arrMod = arrModSizeChart(dataProductDetail.size, 6);

  const [activeIndex, setActiveIndex] = useState(-1);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  return (
    <div className="right-popup">
      <div className="product-tags">{dataProductDetail.productTags}</div>
      <div className="title">{dataProductDetail.titleProduct}</div>
      <div className="color">{dataProductDetail.productColor}</div>
      <div className="discount-products">
        <span className="discount">
          {priceSplitter(dataProductDetail.priceWithPromotion)}
          <span className="currency-unit">đ</span>
        </span>
        <span className="old-price">
          {priceSplitter(dataProductDetail.price)}
          <span className="currency-unit">đ</span>
        </span>
        <span className="sale-off">-10% Off</span>
      </div>
      <div className="thumb-wrapper">
        {dataProductDetail.swathColor.map((item, index) => {
          return (
            <div className="image-item" key={index}>
              <img src={item.image} />
            </div>
          );
        })}
      </div>
      <PopupSizeChart />
      <div className="list-size">
        {dataProductDetail.size.map((item, index) => {
          return (
            <div
              className={`item-size ${index === activeIndex ? 'active' : ''}`}
              onClick={() => handleClick(index)}
              key={index}>
              {item.name}
            </div>
          );
        })}
        {arrMod.map((itemMod) => {
          return <div className="item-size" key={itemMod}></div>;
        })}
      </div>
      <div className="btn-bottom-popup">
        <div className="btn-primary add-to-cart">
          Thêm vào giỏ hàng
          <Icon className="icon-cart" icon="cart" size={24} />
        </div>
        <div className="btn-primary view-product-details">Xem chi tiết sản phẩm</div>
      </div>
    </div>
  );
};

export default RightPopup;
