import React, { useEffect, useState } from 'react';
import menuData from '@data/menu.json';
import BannerMenu from './BannerMenu';
import AttributeMenu from './AttributeMenu';
import MenuRightRowTitle from './MenuRightRowTitle';

const MenuRight = ({ menuActive, setIsActive }) => {
  const [isHidding, setIsHidding] = useState(false);

  useEffect(() => {
    setIsHidding(false);
  }, [menuActive]);

  return (
    <div className="right-menu">
      <div className="right-menu-inner">
        {
          menuData.data.map((item, index) => {
            const classMenuState = menuActive === index ?
              (isHidding ? 'showing' : 'show') :
              (isHidding ? 'hidding' : 'hide');

            return <div key={index} className={classMenuState}>
              {item.listProductType.map((rowMenu, index1) => {
                return (
                  <div className="row-menu" key={index1}>
                    <div className="row">
                      <MenuRightRowTitle setIsActive={setIsActive} rowMenu={rowMenu} />
                      { rowMenu.attribute.length > 0 && <AttributeMenu setIsActive={setIsActive} attributeData={rowMenu.attribute} /> }
                      { rowMenu.banners && <BannerMenu setIsActive={setIsActive} bannerData={rowMenu.banners} /> }
                    </div>
                  </div>
                );
              })}
            </div>;
          })
        }
      </div>
    </div>
  );
};

export default MenuRight;
