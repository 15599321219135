import Icon from '@common/Icon';
import React from 'react';
import { Link } from 'react-router-dom';

const MenuRightRowTitle = ({ rowMenu, setIsActive }) => {
  return (
    <div className={`${rowMenu.attribute.length ? 'col-sm-3' : 'col-sm-12'}`}>
      <div className={`left-category ${!rowMenu.attribute.length ? 'full-width' : ''}`}>
        <div className="category">{rowMenu.name}</div>
        <div className="discover-now">
          <span className="text">
            <Link to="/product-list" onClick={() => {
                setIsActive(false);
              }}
            >Khám phá ngay</Link>
          </span>
          <Icon className="arrow-right" icon="arrow-right-line" />
        </div>
      </div>
    </div>
  );
};

export default MenuRightRowTitle;
