import React from 'react';
import miniCartData from '@data/minicart.json';
import {priceSplitter} from '../../../configs/utils/helpers';

const ContainerMiniCart = () => {
  return (
    <div className="wrapper-container">
      {miniCartData.data.map((item, index) => {
        return (
          <div className="container-mini-cart" key={index}>
            <div className="left-mini-cart">
              <div className="image">
                <img src={item.image} />
              </div>
            </div>
            <div className="right-mini-cart">
              <div className="information-mini-cart">
                <div className="product-name">{item.productName}</div>
                <div className="product-color">{item.productColor}</div>
                <div className="discount-products">
                  <span className="discount">
                    {priceSplitter(item.priceWithPromotion)}
                    <span className="currency-unit">đ</span>
                  </span>
                  <span className="old-price">
                    {priceSplitter(item.price)}
                    <span className="currency-unit">đ</span>
                  </span>
                  <span className="sale-off">-10% Off</span>
                </div>
                <div className="size">{item.size}</div>
                <div className="dflex-spacebetween">
                  <div className="quantity">Số lượng: {item.totalQuantityProduct}</div>
                  <div className="delete">Xóa</div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ContainerMiniCart;
