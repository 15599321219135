import React, { useState } from 'react';

import ColorProduct from './ColorProduct';
import NameProduct from './NameProduct';
import PriceProduct from './PriceProduct';
import TagsProduct from './TagsProduct';
import ThumbProduct from './ThumbProduct';
import ImageCard from './ImageCard';

const ProductItem = ({ item }) => {
  const [image, setImage] = useState('');

  return (
    <article className="product-card">
      <ImageCard item={item} thumb={image} />
      <div className="product-info">
        {item.productTags.length > 0 && (
          <TagsProduct item={item} />
        )}

        {item.productName && (
          <NameProduct item={item} />
        )}

        <PriceProduct item={item} />

        {item.productColor && (
          <ColorProduct item={item} />
        )}

        <ThumbProduct item={item} setThumb={setImage} />
      </div>
    </article>
  )
}

export default ProductItem;
