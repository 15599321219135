import React from "react";
import "./style.scss";

import Subscribe from "./Subcribe";
import FooterSocial from "./FooterSocial";
import BottomFooter from "./BottomFooter";
import NavFooter from "./NavFooter";


const Footer = () => {
  return (
    <footer>
      <div className="navigation-footer">
        <Subscribe />
        <NavFooter />
      </div>

      <FooterSocial />
      <BottomFooter />
    </footer>
  );
};

export default Footer;
