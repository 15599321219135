import React, { useEffect, useState } from 'react';

import './style.scss';
import Poster from './Poster';
import ProductItem from '../ProductItem';

const Products = ({productsPoster, productData, showFilter}) => {
  let productDataTmp = JSON.parse(JSON.stringify(productData.data));

  productsPoster.data.map((item) => {
    productDataTmp.splice((item.position - 1), 0, item);
  });

  return (
    <div className={`${showFilter ? 'col-sm-10' : 'col-sm-12'} products-grid`}>
      <div className="row">
        {productDataTmp.map((item, index) => {
          return (
            <div className={`${showFilter ? 'col-sm-4' : 'col-sm-3'}`} key={index}>
              {item.position ? <Poster /> : <ProductItem item={item} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Products;
