import { combineReducers } from 'redux';
// import { routerReducer } from 'react-router-redux';

// Import custom components
import { user_info } from './userReducer';
import { app_info } from './appReducer';

const rootReducer = combineReducers({
  user_info,
  app_info,
  // routing: routerReducer
});

export default rootReducer;
