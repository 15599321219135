import { UPDATE_USER_INFO, UPDATE_USER_NAME, UPDATE_FAVORITE_PRODUCTS } from '../actions/user';

const init_user_info = {
  name: '',
  phone: '',
  email: '',
  carts: [],
  products: [],
  favorites: []
};

export const user_info = (state = init_user_info, action) => {
  switch (action.type) {
    case UPDATE_USER_INFO:
      return Object.assign({}, state, { user_info: action.user_info });
    case UPDATE_USER_NAME:
      return Object.assign({}, state, { name: action.name });
    case UPDATE_FAVORITE_PRODUCTS:
      return Object.assign({}, state, { favorites: [...action.favorites] });
    default:
      return state;
  }
};
