import React, {useState} from 'react';

import './style.scss';
import Icon from '@common/Icon';
import miniCartData from '@data/minicart.json';
import HeaderMiniCart from './HeaderMiniCart';
import ContainerMiniCart from './ContainerMiniCart';
import FooterMiniCart from './FooterMiniCart';
import NoProduct from './NoProduct';

const MiniCart = () => {
  const [showMiniCart, setShowMiniCart] = useState(false);

  const toggleCode = () => {
    setShowMiniCart(!showMiniCart);
  };

  return (
    <>
      <div onClick={toggleCode}>
        {showMiniCart ? (
          <>
            <Icon icon="cart-fill" size={24} color="#0A0A0A" />
            <div className="cart-number-badge">
              <span className="number">4</span>
            </div>
          </>
        ) : (
          <>
            <Icon icon="cart" size={24} color="#0A0A0A" />
          </>
        )}
      </div>
      {showMiniCart && (
        <div className="wrapper-mini-cart">
          <div className="mini-cart">
            <HeaderMiniCart />
            {miniCartData.data.length == 0 ? (
              <NoProduct />
            ) : (
              <>
                <ContainerMiniCart />
                <FooterMiniCart />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MiniCart;
