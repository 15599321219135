import Icon from '@common/Icon';
import React from 'react';
import Popup from '../Popup';
import {Link} from 'react-router-dom';
import {ANANAS} from '@configs/index';

const ImageCard = ({item, thumb}) => {
  return (
    <div className="card-image">
      {item.comingSoon ? (
        <>
          <img className="image-product-blur" src={item.image} />
          <div className="blur"></div>
          <div className="title">
            {item.comingSoon}
            <div className="date">08/2023</div>
          </div>
        </>
      ) : (
        <>
          <Link to={`${ANANAS.URL.PRODUCT}/${item.id}`}>
            <img className="image-product" src={thumb || item.image} />
          </Link>
          <div className="favorite">
            <Icon className="heart" icon="heart" />
          </div>
          {item.productLabel ? (
            <div className="product-badges">
              <span>{item.productLabel}</span>
            </div>
          ) : (
            ''
          )}
          <Popup />
        </>
      )}
    </div>
  );
};

export default ImageCard;
