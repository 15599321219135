import React, {useState, useRef} from 'react';
import dataDetail from '@data/productDetail.json';
import ZoomImage from '@common/ZoomImage';
import Icon from '@common/Icon';
import CustomLightgallery from '@common/CustomLightgallery';

const LefDetail = () => {
  const dataProductDetail = dataDetail.data;
  const [isPlaying, setIsPlaying] = useState(false);
  const [showPauseIcon, setShowPauseIcon] = useState(false);
  const videoRef = useRef(null);

  const handleMouseEnter = () => {
    setShowPauseIcon(true);
    if (videoRef.current && !isPlaying) {
      videoRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    setShowPauseIcon(false);
    if (videoRef.current && !isPlaying) {
      videoRef.current.pause();
    }
  };

  const handlePlayPauseClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="col-sm-8 left-detail">
      <div className="row">
        {dataProductDetail.imageProduct.map((item, index) => (item.video ? 
          <div
            className="col-sm-6"
            key={index}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div className="video-container">
              <video
                muted
                onClick={(e) => e.target.play()}
                ref={videoRef}
                controls={false}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                onEnded={() => {
                  videoRef.current.currentTime = 0;
                  videoRef.current.play();
                }}>
                <source src={item.video} type="video/mp4" />
              </video>
              {showPauseIcon && (
                <div className="play-pause" onClick={handlePlayPauseClick}>
                  <span className="icon-pause-play">
                    {isPlaying ? <Icon icon="pause" size={36} /> : <Icon className="icon-play" icon="play" size={36} />}
                  </span>
                </div>
              )}
            </div>
          </div>
          : ''
        ))}

        {dataProductDetail.imageProduct.map((item1, index1) => (
          <div className="col-sm-6" key={index1}>
            <div className="images-detail">
              <ZoomImage className="image-zoom" img={item1.image} />
              <CustomLightgallery />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LefDetail;
