import React from 'react';
// import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const About = () => {
  // const users = useSelector((state) => state.user_info);

  return (
    <>
      <Helmet>
        <title>About - Ananas</title>
      </Helmet>

      <div>
        <h1>About Us</h1>
        <ul>
          <li>
            <Link to="/">Homepage</Link>
          </li>
          <li>
            <Link to="/product/4200">Product 1</Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default About;
