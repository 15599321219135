import React from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
// import { updateUserInfo, updateUserName } from '../../configs/actions/user';
import { mapCTPStateToProps } from '../../configs/utils/helpers';
import BannerSlider from '../components/BannerSlider';
import CategoryImage from '../components/CategoryImage';
import ProcessWithIcon from '../components/ProcessWithIcon';
import PromotionBanner from '../components/PromotionBanner';
import ProductCarousel from '../components/ProductCarousel';
// import ProductList from '../components/ProductList';

import Image2 from "@images/banner/poster2.png";
import Image1 from "@images/banner/poster1.png";
// import { ANANAS } from '../../configs';
import { Helmet } from 'react-helmet';
// import ProductDetail from '../components/ProductDetail';
// import MiniCart from '@components/MiniCart';

// console.log(ANANAS);

// import Icomoon from '../components/common/Icomoon';
// import Image1 from '@images/test/20220217_214943.jpg';
// import axios from 'axios';

const Homepage = () => {
  // const [count, setCount] = useState(0);
  // const [typeBanner, setTypeBanner] = useState('');
  // const dispatch = useDispatch();

  // axios.get('/').then(res => {
  //   setTypeBanner(res.banner.type);
  // });

  return (
    <>
      <Helmet>
        <title>Ananas - DiscoverYOU</title>
      </Helmet>

      {/* <ProductDetail /> */}
      <BannerSlider />
      {/* <CarouselProduct /> */}
      <ProductCarousel />
      <PromotionBanner type="1" image={Image1} />
      <CategoryImage column="3" />
      <ProcessWithIcon column="3" />
      <PromotionBanner type="2" image={Image2} />
      <ProductCarousel hasBanner={true} />
      <BannerSlider type="sub" />
      {/* <Icomoon icon="support" size={20} color="black" /> */}
    </>
  );
};

export default connect(mapCTPStateToProps)(Homepage);
