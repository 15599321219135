import { arrModSizeChart, toggleActiveFilter, updateFilters } from '@configs/utils/helpers';
import React from 'react';
import './style.scss';

const Size = ({data, filtersConfig, setFilterConfig}) => {
  const handleFilter = (target, slug) => {
    toggleActiveFilter(target);
    setFilterConfig(updateFilters(filtersConfig, 'size', slug));
  }

  const arrMod = arrModSizeChart(data.childs, 4);

  return (
    <div className="wrapper-item-filter">
      <div className="list-filter">
        <div className="list-size">
          {data.childs.map((item, index) => {
            return (
              <div
                className="item-size"
                key={index}
                onClick={(e) => {
                  handleFilter(e.target, item.name);
                }}
              >
                {item.name}
              </div>
            );
          })}
          { arrMod.map((itemMod) => <div className="item-size" key={itemMod}></div>) }
        </div>
      </div>
    </div>
  );
};

export default Size;
