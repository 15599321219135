import React from 'react';
import {Breadcrumb} from 'antd';
import './style.scss';
import {Link, useLocation} from 'react-router-dom';
import {ANANAS} from '@configs/index';

const CustomBreadCrumb = ({currentTitle = ''}) => {
  const location = useLocation();
  const arrPath = location.pathname.split(/[/]/);
  const arrBreadCrumb = arrPath.map((path, index) => {
    const compTest = index === arrPath.length - 1 ?
      currentTitle :
      <Link to={!path ? '/' : (path === 'product') ? ANANAS.URL.PRODUCT_LIST : ''}>
        {!path ? 'Trang Chủ' : (path === 'product') ? 'Products' : ''}
      </Link>

    return {title: compTest};
  });

  return (
    <div className="br-breadcrumb">
      <div className="breadcrumb">
        <Breadcrumb
          items={arrBreadCrumb}
        />
      </div>
    </div>
  );
};

export default CustomBreadCrumb;
