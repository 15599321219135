import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from "react-dom/client";

import './styles/font-family.scss';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import store from './configs/store/store';
import App from './views/layout/App';

const root = ReactDOM.createRoot(document.getElementById("app"));

root.render(
  <Provider store={store}>
    <Router>
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Inter"
        },
      }}
    >
      <App />
    </ConfigProvider>
    </Router>
  </Provider>,
);
// reportWebVitals(console.log);
