import React from 'react';

const TitleFilter = ({title = ''}) => {
  return (
    <div className="header-filter">
      <div className="title-filter">{title}</div>
    </div>
  )
}

export default TitleFilter;