import React, { useCallback, useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Homepage from '@pages/Homepage';
import About from '@pages/About';
import Header from '@components/Header';
import Footer from '@components/Footer';
import NotFoundPage from '@pages/NotFoundPage';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import SimpleBar from 'simplebar-react';
import ProductListPage from '@pages/ProductListPage';
import ProductDetailPage from '@pages/ProductDetail';

const App = () => {
  const app_info = useSelector(state => state.app_info);
  const ref = useRef();
  const { pathname } = useLocation();
  localStorage.clear();

  useEffect(() => {
    const simpleBarWrap = ref.current.el.querySelector('.simplebar-content-wrapper');
    simpleBarWrap.scrollTo({top: 0})
  }, [pathname]);

  useEffect(() => {
    const $loader = document.getElementById('loader-wrap');
    $loader.classList.add('loaded');

    setTimeout(() => {
      $loader.classList.add('loading-hide');
    }, 1000);
  }, []);

  const handleScroll = useCallback((e) => {
    const dropdownOpen = e.target.querySelectorAll('.ant-dropdown-open');
    const countDropDown = dropdownOpen.length;

    if (countDropDown) {
      dropdownOpen[0].click();
    }
  }, [])

  useEffect(() => {
    const simpleBarWrap = ref.current.el.querySelector('.simplebar-content-wrapper');
    simpleBarWrap.addEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <IntlProvider locale={app_info.lang} messages={app_info.messages} >
      <SimpleBar style={{ height: '100vh' }} forceVisible={false} ref={ref}>
        <Header />
        <main>
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route path="/about" element={<About />} />
            <Route path="/product-list" element={<ProductListPage />} />
            <Route path="/product/:id" element={<ProductDetailPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </main>
        <Footer />
      </SimpleBar>
    </IntlProvider>
  );
};

export default App;
