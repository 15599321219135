import React, {useState} from 'react';
import {Modal} from 'antd';
import Slider from 'react-slick';
import Draggable from 'react-draggable';

import './style.scss';
import Icon from '@common/Icon';
import dataDetail from '@data/productDetail.json';

const CustomLightgallery = () => {
  const [open, setOpen] = useState(false);

  const [zoom, setZoom] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({x: 0, y: 0});

  const handleZoomIn = () => {
    if (zoom < 3) {
      setZoom(zoom + 1);
    }
  };

  const handleZoomOut = () => {
    if (zoom > 1) {
      setZoom(zoom - 1);
      setPosition({x: 0, y: 0});
    }
  };

  function handleStart() {
    setIsDragging(true);
  }

  function handleStop() {
    setIsDragging(false);
  }

  function handleDrag(e, data) {
    setPosition({x: data.x, y: data.y});
  }

  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const dataProductDetail = dataDetail.data;

  function SampleNextArrow(props) {
    const {className, onClick} = props;
    return (
      <div className={className} onClick={onClick}>
        <Icon className="arrow-right" icon="arrow-right-line" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const {className, onClick} = props;
    return (
      <div className={className} onClick={onClick}>
        <Icon className="arrow-left" icon="arrow-left-line" />
      </div>
    );
  }

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    accessibility: true,
    draggable: false,
    focusOnSelect: false,
    touchEnabled: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: () => {
      setZoom(1);
      setPosition({x: 0, y: 0});
    },
  };

  return (
    <div className="popup-custom-lightgallery">
      <div className="fullscreen">
        <span className="icon-full-screen" onClick={showModal}>
          <Icon icon="fullscreen" size={24} />
        </span>
        <Modal
          className="modal-custom-lightgallery"
          centered
          open={open}
          onCancel={handleCancel}
          width={'100%'}
          footer={''}
          closeIcon={<Icon className="close-popup" icon="close" size={36} />}>
          <div className="sider-popup">
            <div className="zoom">
              <div className="zoom-out">
                <Icon className="icon-zoom-out" icon="minus" size={24} onClick={() => handleZoomOut()} />
              </div>
              <div className="zoom-in">
                <Icon className="icon-zoom-in" icon="plus" size={24} onClick={() => handleZoomIn()} />
              </div>
            </div>
            <Slider {...settings}>
              {dataProductDetail.imageProduct.map((item, index) => {
                return (
                  <div key={index}>
                    <Draggable
                      bounds={{top: -300, left: -200, right: 200, bottom: 300}}
                      onStart={handleStart}
                      onStop={handleStop}
                      disabled={zoom === 1}
                      position={position}
                      onDrag={handleDrag}>
                      <div className="img-slider">
                        <img src={item.image} style={{transform: `scale(${zoom})`, maxWidth: '100%'}} />
                      </div>
                    </Draggable>
                  </div>
                );
              })}
            </Slider>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default CustomLightgallery;
