import React from 'react';
import Slider from 'react-slick';

import Icon from '@common/Icon';
import PopupData from '@data/popup.json';

const LeftPopup = () => {
  const dataProductDetail = PopupData.data;

  function SampleNextArrow(props) {
    const {className, onClick} = props;
    return (
      <div className={className} onClick={onClick}>
        <Icon className="arrow-right" icon="arrow-right-line" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const {className, onClick} = props;
    return (
      <div className={className} onClick={onClick}>
        <Icon className="arrow-left" icon="arrow-left-line" />
      </div>
    );
  }

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    accessibility: true,
    focusOnSelect: false,
    touchEnabled: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="left-popup">
      <div className="product-badges">Sale off</div>
      <div className="favorite">
        <Icon className="heart" icon="heart" size={24} />
      </div>
      <Slider {...settings}>
        {dataProductDetail.imageProduct.map((item, index) => {
          return (
            <div className="img-slider" key={index}>
              <img src={item.image} />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default LeftPopup;
