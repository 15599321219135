import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import Image1 from "@images/category/forboys.png";
import Image2 from "@images/category/forgirls.png";
import Image3 from "@images/category/forkids.png";
import Section from '../../common/Section';

const CategoryImage = (props) => {
  const [columnClass, setColumnClass] = useState('col-sm-6');
  const dataTmp = [
    {
      image: Image1,
      title: 'For Boys',
      link: '/about'
    },
    {
      image: Image2,
      title: 'For Girls',
      link: '/about'
    },
    {
      image: Image3,
      title: 'For Kids',
      link: '/about'
    },
    {
      image: Image1,
      title: 'For Everyone',
      link: '/about'
    }
  ];

  useEffect(() => {
    if ((props.column === '2')) {
      setColumnClass('col-sm-6');
    } else if ((props.column === '3')) {
      setColumnClass('col-sm-4');
    } else if ((props.column === '4')) {
      setColumnClass('col-sm-3');
    } else {
      setColumnClass('col-sm-4');
    }
  }, []);

  return (
    <Section>
      <div className="category">
        <div className="row">
          {
            dataTmp.map((item, index) => {
              if (index >= parseInt(props.column)) {
                return false;
              }

              return (
                <div className={columnClass} key={index}>
                  <div className="item-category">
                    <img className="image-category" src={item.image} />
                    <div className="item-group">
                      <div className="title-for">{item.title}</div>
                      <Link className="btn-primary" to={item.link}>
                        Khám phá ngay
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </Section>
  );
};

export default CategoryImage;
