import React from 'react';

const ColorProduct = ({ item }) => {
  return (
    <div className={`color-classification ${item.listColors.length <= 1 ? 'no-hover' : ''}`}>
      {item.productColor}
    </div>
  )
}

export default ColorProduct;
