export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
export const UPDATE_FAVORITE_PRODUCTS = 'UPDATE_FAVORITE_PRODUCTS';

export const updateUserInfo = user_info => ({
  type: UPDATE_USER_INFO,
  user_info: user_info
});

export const updateUserName = name => ({
  type: UPDATE_USER_NAME,
  name: name
});

export const updateFavorite = favorites => {
  return ({
    type: UPDATE_FAVORITE_PRODUCTS,
    favorites: [...favorites]
  })
};
