import React, {useState} from 'react';
import './style.scss';

const ZoomImage = ({img, className = ''}) => {
  const stylesDefault = {
    backgroundImage: `url(${img})`,
    backgroundSize: '200%',
  };
  const [stylesBg, setStylesBg] = useState({
    backgroundPosition: '0% 0%',
  });

  const handleMouseMove = (e) => {
    const {left, top, width, height} = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;

    setStylesBg({backgroundPosition: `${x}% ${y}%`});
  };

  return (
    <div className={`zoom-image ${className}`}>
      <figure onMouseMove={handleMouseMove} style={{...stylesBg, ...stylesDefault}}>
        <img src={img} />
      </figure>
    </div>
  );
};

export default ZoomImage;
