import React, { useEffect, useState } from 'react';
import './style.scss';
import productsOriginal from '@data/productList.json';
import productsPoster from '@data/productsPoster.json';

import Section from '../../common/Section';
import CustomBreadCrumb from '../CustomBreadCrumb';
import Filter from './Filter';
import ProductListUI from './ProductListUI';
import SortProduct from './SortProduct';
// import { ApiService } from '@configs/utils/api';

const ProductList = () => {
  const [showFilter, setShowFilter] = useState(true);
  const [productData, setProductData] = useState(productsOriginal);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    const titleProduct = document.querySelector('.title-product');
    const topTitle = titleProduct ? titleProduct.offsetTop - 80 : 0;

    scrollTop > topTitle ?
      document.body.classList.add('is-sticky') :
      document.body.classList.remove('is-sticky');
  }

  useEffect(() => {
    const simpleBarWrap = document.querySelector('.simplebar-content-wrapper');
    simpleBarWrap.addEventListener('scroll', handleScroll)
  });

  const [filtersConfig, setFilterConfig] = useState({
    status: [],
    collections: [],
    colors: [],
    size: [],
    prices: [],
    sort: 'asc'
  });

  // const filters = (filtersConfig) => {
  //   const url = 'https://dummyjson.com/products';

  //   ApiService.query(url, filtersConfig).then((res) => {
  //     console.log(res.data);
  //     setProductData(productFilter);
  //   });
  // };

  useEffect(() => {
    // filters(filtersConfig);
    console.log(filtersConfig);
  }, [filtersConfig]);

  return (
    <>
      {/* <CustomBreadCrumb currentTitle="Sản phẩm cho nam" /> */}
      <Section padding="no" className="product-list">
        <div className="title-product">Sản phẩm cho nam</div>
        <SortProduct showFilter={showFilter} setShowFilter={setShowFilter}/>
        <div className="row" style={{ padding: '0 15px' }}>
          <Filter filtersConfig={filtersConfig} setFilterConfig={setFilterConfig} showFilter={showFilter} />
          <ProductListUI productsPoster={productsPoster} productData={productData} showFilter={showFilter} />
        </div>
      </Section>
    </>
  );
};

export default ProductList;
