import React, { useState } from 'react';
import flag from '@images/footer/flag.svg';
import flagEn from '@images/footer/flag-en.svg';
import { useDispatch, useSelector } from "react-redux";
import { updateLanguage } from '@configs/actions/app';
import { FormattedMessage } from "react-intl";
import { Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import Section from '@common/Section';
import Icon from '@icon';

const privacyItems = [
  { label: <a href="/about">Tra cứu đơn hàng</a> },
  { label: <a href="/about">Tìm cửa hàng</a> },
  { label: <a href="/about">Đăng ký thành viên</a> },
  { label: <a href="/about">Gửi feedback</a> }
];

const BottomFooter = () => {
  const lang = useSelector(state => state.app_info.lang);
  const dispatch = useDispatch();
  const [activeDropdown, setActiveDropdown] = useState(false);

  return (
    <div className="footer-bottom">
      <Section container="small">
        <div className="sub-footer">
          <div className="sub-footer-left">
            <div className="language">
              <button onClick={() => {
                dispatch(updateLanguage(lang === 'en' ? 'vi' : 'en'))
              }}>
                <img src={lang === 'en' ? flagEn : flag} />
              </button>
              <FormattedMessage id="footer.lang" />
            </div>
            <div className="coppy-right">© 2022 Ananas. All rights reserved.</div>
          </div>
          <div className="sub-footer-right">
            <div className="terms-of-use">
              <Link to="/about">
                Điều khoản sử dụng
              </Link>
            </div>
            <div className={"policy-privacy " + (activeDropdown ? 'active' : '')}>
              <Dropdown
                className="dropdown-select"
                overlayClassName="dropdown-select"
                menu={{ items: privacyItems }}
                trigger={['click']} onOpenChange={(open) => {
                  setActiveDropdown(open)
                }}
                getPopupContainer={() => document.querySelector('.simplebar-content')}
              >
                <span>
                  <span className="label-dropdown">
                    Chính sách & quyền riêng tư
                  </span>
                  <Icon className="icon-arrow-up" icon={activeDropdown ? "arrow-down" : "arrow-up"} size={24} color="black" />
                </span>
              </Dropdown>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default BottomFooter;
