import React, { useEffect } from 'react';
import './style.scss';
import filterData from '@data/filter.json';
import Icon from '@icon';
import { Collapse } from 'antd';
const { Panel } = Collapse;
import colors from '@colors';

import Status from './Status';
import Collection from './Collection';
import Color from './Color';
import Size from './Size';
import Price from './Price';
import TitleFilter from './TitleFilter';

const Filter = ({showFilter, filtersConfig, setFilterConfig}) => {
  return (
    <div className={`col-sm-2 wrapper-filter ${showFilter ? 'show' : 'hide'}`}>
      <Collapse
        defaultActiveKey={[...filterData.data.keys()]}
        expandIcon={({ isActive }) => isActive ?
          <Icon className="minus" icon="minus" size={24} color={colors.black} /> :
          <Icon className="minus" icon="plus" size={24} color={colors.black} />
        }
        expandIconPosition="end"
        ghost
        style={{padding: 0}}
      >
        {
          filterData.data.map((item, index) => {
            const category = item.category;
            let CategoryComp = null;

            if (category === 'status') {
              CategoryComp = <Status
                filtersConfig={filtersConfig}
                setFilterConfig={setFilterConfig}
                data={item} />
            } else if (category === 'collections') {
              CategoryComp = <Collection
                filtersConfig={filtersConfig}
                setFilterConfig={setFilterConfig}
                data={item} />
            } else if (category === 'colors') {
              CategoryComp = <Color
                filtersConfig={filtersConfig}
                setFilterConfig={setFilterConfig}
                data={item} />
            } else if (category === 'sizes') {
              CategoryComp = <Size
                filtersConfig={filtersConfig}
                setFilterConfig={setFilterConfig}
                data={item} />
            } else if (category === 'prices') {
              CategoryComp = <Price
                filtersConfig={filtersConfig}
                setFilterConfig={setFilterConfig}
                data={item} />
            }

            return (
              <Panel
                header={<TitleFilter title={item.categoryName} />}
                key={index} className="custom-panel">
                {CategoryComp}
              </Panel>
            )
          })
        }
      </Collapse>
    </div>
  );
};

export default Filter;
