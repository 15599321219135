import React from 'react';

const HeaderMiniCart = () => {
  return (
    <div className="header-mini-cart">
      <span className="title">Giỏ Hàng</span>
      <span className="quantity"> (02)</span>
    </div>
  );
};

export default HeaderMiniCart;
