import React from 'react';
import { Helmet } from 'react-helmet';
import ProductList from '@components/ProductList';

const ProductListPage = () => {
  return (
    <>
      <Helmet>
        <title>Product List - Ananas</title>
      </Helmet>
      <ProductList />
    </>
  );
};

export default ProductListPage;
