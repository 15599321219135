export const COLORS = {
  white: '#FFFFFF',
  black: '#0A0A0A',
  orange: '#F36C21',
  red: '#CE1212',
  green: '#056A37',
  yellow: '#F5D255',
  lightGrey: '#F0F0F0',
  grey: '#CCCCCC',
  darkGrey: '#7D7D7D',
}

export default COLORS;
