import React from 'react';
import {Link} from 'react-router-dom';
import Icon from '@icon';
import {ANANAS} from '../../../configs';
import {priceSplitter} from '../../../configs/utils/helpers';

const ItemCarouselBanner = ({item, addFavorite, isFavorite}) => {
  return (
    <div className="item-carousel">
      <div className="image-item">
        <img src={item.image} />
        <div className="overlay">
          <img src={item.imageHover} />
          <div className="discover-now">
            <Link className="btn-primary --border-line --transparent" to={`${ANANAS.URL.PRODUCT}/${item.id}`}>
              <span className="text">Mua nhanh</span>
              <span className="icon-cart">
                <Icon className="cart" icon="cart" size={18} />
              </span>
            </Link>
          </div>
        </div>
        {item.productBadges ? <div className="product-badges">{item.productBadges}</div> : ''}
        <div
          className="favorite"
          onClick={() => {
            addFavorite(item.id);
          }}>
          <Icon
            className={`heart ${isFavorite ? 'active' : ''}`}
            icon={isFavorite ? 'heart-fill' : 'heart'}
          />
        </div>
      </div>
      <div className="product-info">
        <div className="product-tags">{item.productTags}</div>
        <div className="product-name">
          <Link to={`${ANANAS.URL.PRODUCT}/${item.id}`}>{item.productName}</Link>
        </div>
        <div className="product-price-wrapper">
          <div className="discount-products">
            <span className="discount">
              {priceSplitter(item.price)} <span className="currency-unit">đ</span>
            </span>
          </div>
        </div>
        <div className="color-classification">{item.productColor}</div>
      </div>
    </div>
  );
};

export default ItemCarouselBanner;
