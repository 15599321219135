import React, {useState} from 'react';
import {Button, Modal} from 'antd';

import './style.scss';
import Icon from '@common/Icon';
import sizeChart from '@images/popup/size-chart.png';

const PopupSizeChart = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <div className="popup-size-chart">
      <div className="size-type">EU Size</div>
      <div className="view-size">
        <span className="size-guide" onClick={showModal}>
          Xem Bảng Size
          <Icon className="icon-size-chart" icon="size-chart" size={24} />
        </span>
        <Modal
          className="modal-size-chart"
          centered
          open={open}
          onCancel={handleCancel}
          width={'100%'}
          footer={''}
          closeIcon={<Icon className="close-popup" icon="close" size={36} />}>
          <div className="wrapper-size-chart">
            <div className="title">Size Chart</div>
            <div className="imge-size-chart">
              <img src={sizeChart} />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default PopupSizeChart;
