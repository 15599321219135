import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@icon';
import colors from '@colors';

import bocongthuong from '@images/footer/bocongthuong.svg';

const FooterSocial = () => {
  return (
    <div className="social-network">
      <ul>
        <li>
          <Link to="/about">
            <Icon icon="facebook" size={40} color={colors.black} />
          </Link>
        </li>
        <li>
          <Link to="/about">
            <Icon icon="instagram" size={40} color={colors.black} />
          </Link>
        </li>
        <li>
          <Link to="/about">
            <Icon icon="linkedin" size={40} color={colors.black} />
          </Link>
        </li>
        <li>
          <Link to="/about">
            <Icon icon="pinterest" size={40} color={colors.black} />
          </Link>
        </li>
        <li>
          <Link to="/about">
            <Icon icon="twitter" size={40} color={colors.black} />
          </Link>
        </li>
        <li>
          <Link to="/about">
            <Icon icon="youtube" size={40} color={colors.black} />
          </Link>
        </li>
      </ul>
      <span className="bct">
        <Link to="/about">
          <img src={bocongthuong} />
        </Link>
      </span>
    </div>
  );
};

export default FooterSocial;
