import React from 'react';

import {Slider} from 'antd';
import './style.scss';
import Section from '../../common/Section';
import CustomBreadCrumb from '../CustomBreadCrumb';
import dataDetail from '@data/productDetail.json';
import Icon from '@common/Icon';
import ProductCarousel from '@components/ProductCarousel';
import LefDetail from './LeftDetail';
import RightDetail from './RightDetail';

const ProductDetail = () => {
  const dataProductDetail = dataDetail.data;

  return (
    <>
      <CustomBreadCrumb currentTitle={dataProductDetail.titleProduct} />
      <Section>
        <div className="product-detail">
          <div className="row">
            <LefDetail />
            <RightDetail />
          </div>
          <div className="review">
            <div className="dflex-spacebetween">
              <div>số sao đánh giá</div>
              <div className="write-review">Viết đánh giá</div>
            </div>
            <div className="size">
              <span className="text">Kích Cỡ</span>
              <span className="increment-bar">
                <div className="rating-level">
                  <span>Chật</span>
                  <span>Vừa</span>
                  <span>Rộng</span>
                </div>
                <div>
                  <Slider defaultValue={80} tooltip={{open: false}} />
                </div>
              </span>
            </div>
            <div className="durability">
              <div className="text">Độ Bền</div>
              <div className="increment-bar">
                <div className="rating-level">
                  <span>Không bền</span>
                  <span>Trung bình</span>
                  <span>Rất bền</span>
                </div>
                <div>
                  <Slider defaultValue={60} tooltip={{open: false}} />
                </div>
              </div>
            </div>
            <div className="applicability">
              <div className="text">Tính Ứng Dụng</div>
              <div className="increment-bar">
                <div className="rating-level">
                  <span>Thấp</span>
                  <span>Vừa</span>
                  <span>Cao</span>
                </div>
                <div>
                  <Slider defaultValue={95} tooltip={{open: false}} />
                </div>
              </div>
            </div>
          </div>
          {/* <ProductCarousel /> */}
        </div>
      </Section>
    </>
  );
};

export default ProductDetail;
