import { Slider } from 'antd';
import React, { useState } from 'react';
import { priceSplitter } from '../../../../configs/utils/helpers';
import './style.scss';

const Price = ({ data, filtersConfig, setFilterConfig }) => {
  const [fromPrice, setFromPrice] = useState(data.min);
  const [toPrice, setToPrice] = useState(data.max);

  const onChange = (value) => {
    setFromPrice(value[0]);
    setToPrice(value[1]);

    filtersConfig.prices = [value[0], value[1]];
    setFilterConfig({...filtersConfig});
  };

  return (
    <div className="wrapper-item-filter">
      <div className="list-filter">
        <div className="price-splitter">
          <span>{priceSplitter(fromPrice)}</span>
          <span>{priceSplitter(toPrice)}</span>
        </div>
        <Slider
          range
          min={data.min}
          max={data.max}
          defaultValue={[data.min, data.max]}
          onChange={onChange}
          step={data.step}
          tooltip={{ open: false }}
        />
      </div>
    </div>
  );
};

export default Price;
