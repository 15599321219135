import React from "react";
import Icon from '@icon';
import { Link } from "react-router-dom";

import navFooter from "@data/footer.json"
import logoananas from "@images/header/logo-ananas.svg";
import colors from "@colors";
import Section from "../../common/Section";

const NavFooter = () => {
  return (
    <Section container="small">
      <div className="left-nav-footer">
        {
          navFooter.data.map((item, index) => {
            return <div className="item-nav" key={index}>
              {item.heading ? <div className="footer-heading">{item.heading}</div> : ''}
              <ul className="footer-list">
                {
                  item.listItem ?
                  item.listItem.map((menuItem ,index2) => {
                    return (
                      <li key={index2}>
                        <Link to={menuItem.link}>
                          {menuItem.icon ? <Icon className="item-icon" icon={menuItem.icon} size={24} color={colors.black} /> : ''}
                          <span className="text">{menuItem.text}</span>
                        </Link>
                      </li>
                    )
                  }) : ''
                }
              </ul>
            </div>
          })
            }
      </div>
      <div className="right-logo">
        <div className="logo-ananas">
          <Link to="/">
            <img src={logoananas} />
          </Link>
        </div>
      </div>
    </Section>
  )
}

export default NavFooter;
