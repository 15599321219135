import React from 'react';

import dataDetail from '@data/productDetail.json';
import StarNavy from '@images/product-detail/star-navy.svg';
import StarGrey from '@images/product-detail/star-grey.svg';
import {getWidthStar} from '@configs/utils/helpers';

const ReviewStar = () => {
  const dataProductDetail = dataDetail.data;
  const ratings = dataProductDetail.reviews;
  const pointStar = (ratings.totalPoint / ratings.totalReview) * 5;
  const widthStar = getWidthStar(pointStar);

  return (
    <div className="review-star">
      <div className="rating-star">
        <span className="star-grey">
          <img src={StarGrey} alt="star grey" />
        </span>
        <span className="star-dark-grey"></span>
        <span className="star-navy" style={{width: widthStar}}>
          <img src={StarNavy} alt="star grey" />
        </span>
      </div>
      <span className="number">(30)</span>
    </div>
  );
};

export default ReviewStar;
