import React from "react";
import Slider from "react-slick";
import './carousel-product.scss';
import { useDispatch, useSelector } from "react-redux";
import ItemCarouselProduct from "./ItemCarouselProduct";
import { updateFavorite } from '@configs/actions/user';

const CarouselProduct = ({ data, config, title='' }) => {
  const user_info = useSelector(state => state.user_info);
  const dispatch = useDispatch();
  const favorites = useSelector(state => state.user_info.favorites);

  const addFavorite = (id) => {
    let arrFavorites = user_info.favorites;
    const indexFavorite = arrFavorites.indexOf(id);

    if (arrFavorites.includes(id)) {
      arrFavorites.splice(indexFavorite, 1);
      dispatch(updateFavorite([...arrFavorites]));
    } else {
      dispatch(updateFavorite([...arrFavorites, id]));
    }
  };

  return (
    <>
      <div className="title-carousel">{title}</div>
      <Slider {...config}>
        { data.map((item, index) => {
          return (
            <ItemCarouselProduct
              item={item}
              key={index}
              isFavorite={favorites.includes(item.id)}
              addFavorite={addFavorite}
            />
          );
        })}
      </Slider>
    </>
  );
};

export default CarouselProduct;
