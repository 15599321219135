import React, {useState} from 'react';
import Icon from '@icon';
import dataProductSlider from '@data/productsSlider.json';
import Section from '../../common/Section';

import CarouselWithBanner from './CarouselWithBanner';
import CarouselProduct from './CarouselProduct';

const ProductCarousel = ({hasBanner}) => {
  const [widthProgress, setWidthProgress] = useState((3 / dataProductSlider.data.length) * 100);

  function SampleNextArrow(props) {
    const {className, onClick} = props;
    return (
      <div className={className} onClick={onClick}>
        <Icon className="arrow-right" icon="arrow-right-line" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const {className, onClick} = props;
    return (
      <div className={className} onClick={onClick}>
        <Icon className="arrow-left" icon="arrow-left-line" />
      </div>
    );
  }

  const configSlider = {
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    swipe: true,
    draggable: true,
    focusOnSelect: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (currentSlide, nextSlide) => {
      if (hasBanner) {
        let calc = ((nextSlide + 3) / dataProductSlider.data.length) * 100;

        if (currentSlide === nextSlide) {
          return;
        }

        if (currentSlide > nextSlide) {
          calc = ((nextSlide + 3) / dataProductSlider.data.length) * 100;
        }

        setWidthProgress(calc);
      }
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          arrows: false,
          dost: false,
        },
      },
    ],
  };

  return (
    <Section paddingMb="no">
      <div className={hasBanner ? 'banner-carousel' : 'carousel-slider'}>
        {hasBanner ? (
          <CarouselWithBanner
            data={dataProductSlider.data}
            config={configSlider}
            percentProgress={widthProgress}
            className="has-banner"
          />
        ) : (
          <CarouselProduct
            data={dataProductSlider.data}
            config={configSlider}
            title={dataProductSlider.title}
          />
        )}
      </div>
    </Section>
  );
};

export default ProductCarousel;
