import React from 'react';
import { useState } from 'react';

import Icon from '@icon';
import './style.scss';
import { toggleActiveFilter, updateFilters } from '@configs/utils/helpers';

const Collection = ({ data, filtersConfig, setFilterConfig }) => {
  const [checked, setChecked] = useState(false);
  const collectionPerPage = 4;
  const [countCollection, setCountCollection] = useState(collectionPerPage);

  const handleFilter = (target, slug) => {
    toggleActiveFilter(target);
    setFilterConfig(updateFilters(filtersConfig, 'collections', slug));
  }

  return (
    <div className="wrapper-item-filter">
      <div className="list-filter">
        <div className="collection">
          {data.childs.map((item, index) => {
            return (
              <label
                className={`filter-item-checkbox ${
                  index > countCollection - 1 ? 'hide' : ''
                }`}
                key={index}
              >
                {item.name}
                <span> ({item.quantity})</span>
                <input type="checkbox"
                  onChange={() => {setChecked(!checked)}}
                  onClick={(e) => {handleFilter(e.target, item.slug)}}
                />
                <span className="checkmark"></span>
              </label>
            );
          })}
        </div>
      </div>
      {countCollection < data.childs.length && (
        <div
          className="view-more"
          onClick={() => {
            setCountCollection(countCollection + collectionPerPage);
          }}>
          <Icon className="plus" icon="plus" size={18} />
          Xem thêm
        </div>
      )}
    </div>
  );
};

export default Collection;
