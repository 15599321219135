import ProductDetail from '@components/ProductDetail';
import React from 'react'
import { Helmet } from 'react-helmet';
// import { useParams } from 'react-router-dom';

const ProductDetailPage = () => {
  // const { id } = useParams();

  return (
    <>
      <Helmet>
        <title>Product detail - Ananas</title>
      </Helmet>
      <ProductDetail />
    </>
  )
}

export default ProductDetailPage;
