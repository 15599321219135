import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

import './sub-slider.scss';
import Image1 from '@images/sub-slider/saigon1980.png';
import Image2 from '@images/sub-slider/urbvs.png';
import Image3 from '@images/sub-slider/dictonary.png';
import Section from '../../common/Section';

let dragging = false;

const SubBannerSlider = () => {
  const dataTmp = [
    {
      image: Image1,
      link: '/about'
    },
    {
      image: Image2,
      link: '/about'
    },
    {
      image: Image3,
      link: '/about'
    },
    {
      image: Image1,
      link: '/about'
    },
    {
      image: Image2,
      link: '/about'
    },
    {
      image: Image3,
      link: '/about'
    }
  ];

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ],
    beforeChange: () => dragging = true,
    afterChange: () => dragging = false,
  };

  return (
    <Section>
      <div className="sub-slider">
        <Slider {...settings}>
          {dataTmp.map((item, index) => {
            return (
              <div className="collection" key={index}>
                <Link to={item.link} onClick={(e)=> dragging && e.preventDefault()}>
                  <div className="image-collection">
                    <img src={item.image} />
                  </div>
                  <div className="title-collection">
                    Track 6 unnamed no.1 in c minor
                  </div>
                </Link>
              </div>
            );
          })}
        </Slider>
      </div>
    </Section>
  );
};

export default SubBannerSlider;
