import React, {useEffect, useState} from 'react';
import Icon from '@icon';
import MegaMenu from '../MegaMenu';

import {Link} from 'react-router-dom';
import logoananas from '@images/header/logo-ananas.svg';
import {FormattedMessage} from 'react-intl';
import Section from '../../common/Section';
import {freeze} from '../../../configs/utils/helpers';
import MiniCart from '@components/MiniCart';

const MenuBarTop = () => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    freeze(isActive);
  }, [isActive]);

  return (
    <div>
      <Section padding="no">
        <div className="nav">
          <div className="row">
            <div className="col-md-5 header-menu">
              <div
                className={`hamburger-icon ${isActive ? 'open' : ''}`}
                onClick={() => {
                  setIsActive(!isActive);
                }}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="col-md-2 header-logo">
              <Link
                to="/"
                onClick={() => {
                  setIsActive(false);
                }}>
                <img src={logoananas} />
              </Link>
            </div>
            <div className="col-md-5 utility">
              <div className="header-search">
                <button className="btn-search">
                  <FormattedMessage id="header.searchText" />
                  <Icon className="icon-search" icon="search" size={24} color="#0A0A0A" />
                </button>
              </div>
              <div className="list-utility">
                <ul>
                  <li>
                    <Link to="/about">
                      <Icon icon="user" size={24} color="#0A0A0A" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <Icon icon="heart" size={24} color="#0A0A0A" />
                    </Link>
                  </li>
                  <li>
                    <MiniCart />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <MegaMenu isActive={isActive} setIsActive={setIsActive} />
    </div>
  );
};

export default MenuBarTop;
