import React, {useState} from 'react';
import {Button, Modal} from 'antd';

import './style.scss';
import Icon from '@icon';
import LeftPopup from './LeftPopup';
import RightPopup from './RightPopup';

const Popup = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div className="shopping-now">
      <div className="btn-with-icon" onClick={showModal}>
        <span className="text">Mua nhanh</span>
        <span className="icon-cart">
          <Icon className="cart" icon="cart" size={24} />
        </span>
      </div>
      <Modal
        className="popup-product"
        centered
        open={open}
        onCancel={handleCancel}
        width={999}
        footer={''}
        closeIcon={<Icon className="close-popup" icon="close" size={36} />}>
        <div className="modal-quick-view">
          <LeftPopup />
          <RightPopup />
        </div>
      </Modal>
    </div>
  );
};
export default Popup;
