import React from 'react';

import dataDetail from '@data/productDetail.json';

const ThumbWrapper = () => {
  const dataProductDetail = dataDetail.data;

  return (
    <div className="thumb-wrapper">
      {dataProductDetail.swathColor.map((item, index) => {
        return (
          <div className="image-item" key={index}>
            <img src={item.image} />
          </div>
        );
      })}
    </div>
  );
};

export default ThumbWrapper;
