import _ from 'lodash';
import { createStore, applyMiddleware, compose } from 'redux';

// middlewares
import logger from 'redux-logger';

// Import custom components
import rootReducer from '../reducers/rootReducer';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from '../utils/helpers';

/**
 * Create a Redux store that holds the app state.
 */

const preloaded_state = loadStateFromLocalStorage();

const store = createStore(
  rootReducer,
  preloaded_state,
  compose(
    applyMiddleware(logger),
    window.devToolsExtension
      ? window.devToolsExtension()
      : function (f) {
          return f;
        }
  )
);

store.subscribe(_.throttle(() => {
  saveStateToLocalStorage(store.getState());
}, 1000));

export default store;
