import React from 'react';
import { Link } from 'react-router-dom';

const AttributeMenu = ({ attributeData, setIsActive }) => {
  return (
    <div className="col-sm-9">
      <div className="right-category">
        {
          attributeData.map((categoryItem, index) => {
            return (
              <div className="category-item" key={index}>
                <div className="type-text">{categoryItem.name}</div>
                <div className="list-type">
                  {categoryItem.type.map((productType, index2) => {
                    return (
                      <span className="product-name" key={index2}>
                        <Link to="/product-list" onClick={() => {setIsActive(false)}}>{productType}</Link>
                      </span>
                    );
                  })}
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default AttributeMenu;
