import React, { useState } from 'react';
import './style.scss';

import Section from '../../common/Section';
import MenuRight from './MenuRight';
import SiderbarMenu from './SiderbarMenu';

const MegaMenu = ({ isActive, setIsActive }) => {
  const [menuActive, setMenuActive] = useState(0);

  return (
    <div className={`mega-menu-wrapper ${isActive ? 'active' : ''}`}>
      <div className="mega-menu">
        <Section padding="no">
          <div className="mega-menu-inner">
            <SiderbarMenu
              menuActive={menuActive}
              setMenuActive={setMenuActive}
              setIsActive={setIsActive}
            />
            <MenuRight
              menuActive={menuActive}
              setIsActive={setIsActive}
            />
          </div>
        </Section>
      </div>
    </div>
  );
};

export default MegaMenu;
