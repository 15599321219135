import React from 'react';

const BannerMenu = ({ bannerData }) => {
  return (
    <div className="col-sm-12 banner-mega-menu">
      {bannerData.map((banner, index) => {
        return (
          <div className="wrapper" key={index}>
            <div className="banner-item">
              <img src={banner.image} />
            </div>
            <div className="title">{banner.title}</div>
            <div className="content">{banner.content}</div>
          </div>
        )
      })}
    </div>
  );
};

export default BannerMenu;
