import React from 'react';

const FooterMiniCart = () => {
  return (
    <div className="footer-mini-cart">
      <div className="block-price">
        <div className="subtotal">
          <div className="label-mini-cart">Tổng Cộng</div>
          <div className="price">1.590.000 VND</div>
        </div>
        <div className="subtotal-vat">
          <div className="title-vat">VAT (đã bao gồm)</div>
          <div className="vat-price">48.569 VND</div>
        </div>
      </div>
      <div className="btn-primary payment">Thanh toán</div>
      <div className="btn-primary view-cart">Xem giỏ hàng</div>
      <div className="membership-polyci">
        Thành viên nhà Dứa được miễn ship.&nbsp;
        <span className="member-registration">Đăng ký thành viên</span>&nbsp; để hưởng ưu đãi.
      </div>
    </div>
  );
};

export default FooterMiniCart;
