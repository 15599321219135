import React from 'react';
import './style.scss';

import Topbar from './Topbar';
// import Section from '../../common/Section';
import MenuBarTop from './MenuBarTop';

function Header() {
  return (
    <>
      <Topbar />
      <header>
        <MenuBarTop />
      </header>
    </>
  );
}
export default Header;
