import React, {useState} from 'react';
import {Tooltip} from 'antd';

import dataDetail from '@data/productDetail.json';
import {arrModSizeChart} from '@configs/utils/helpers';
import {ANANAS} from '@configs/index';

const Size = () => {
  const dataProductDetail = dataDetail.data;
  const arrMod = arrModSizeChart(dataProductDetail.size, 6);
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
    console.log('chon size');
  };

  const clickSize = () => {
    console.log('het size');
  };

  return (
    <div className="list-size">
      {dataProductDetail.size.map((item, index) => {
        return (
          <div
            className={`item-size ${index === activeIndex ? 'active' : ''} ${!item.status ? 'disabled' : ''}`}
            onClick={() => {
              item.status ? handleClick(index) : clickSize();
            }}
            key={index}>
            {item.status ? (
              item.name
            ) : (
              <Tooltip placement="bottomLeft" title={'Hết size'} color={ANANAS.COLORS.white} arrow={false}>
                {item.name}
              </Tooltip>
            )}
          </div>
        );
      })}
      {arrMod.map((itemMod) => {
        return <div className="item-size" key={itemMod}></div>;
      })}
    </div>
  );
};

export default Size;
