import React from 'react';
import Slider from 'react-slick';
import './style.scss';
import banner from '@images/banner-carousel/banner-carousel.png';
import ItemCarouselBanner from './ItemCarouselBanner';
import { useDispatch, useSelector } from 'react-redux';
import { updateFavorite } from '../../../configs/actions/user';

const CarouselWithBanner = ({ data, config, percentProgress, className }) => {
  const user_info = useSelector(state => state.user_info);
  const dispatch = useDispatch();
  const favorites = useSelector(state => state.user_info.favorites);

  const addFavorite = (id) => {
    let arrFavorites = user_info.favorites;
    const indexFavorite = arrFavorites.indexOf(id);

    if (arrFavorites.includes(id)) {
      arrFavorites.splice(indexFavorite, 1);
      dispatch(updateFavorite([...arrFavorites]));
    } else {
      dispatch(updateFavorite([...arrFavorites, id]));
    }
  };

  return (
    <div className={`row ${className}`}>
      <div className="col-sm-6 col-md-6 col-xs-12 pa-0">
        <div className="left-banner">
          <picture>
            <source media="(min-width:992px)" srcSet={banner} />
            <img className="image-banner" src={banner} />
          </picture>
        </div>
      </div>
      <div className="col-sm-6 col-md-6 col-xs-12">
        <div className="right-carousel">
          <Slider {...config}>
            {
              data.map((item, index) => {
                return (
                  <ItemCarouselBanner
                    item={item}
                    key={index}
                    isFavorite={favorites.includes(item.id)}
                    addFavorite={addFavorite}
                  />
                )
              })
            }
          </Slider>
          <div className="progress-border">
            <div className="progress-line" style={{ width: `${percentProgress}%` }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselWithBanner;
