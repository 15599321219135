import React from 'react';
import DefaultSlider from './SliderDefault';
import SubBannerSlider from './SubBannerSlider';

const BannerSlider = ({type}) => {
  return (
    <>
      { type === 'sub' ? <SubBannerSlider /> : <DefaultSlider /> }
    </>
  );
};

export default BannerSlider;
