import { toggleActiveFilter, updateFilters } from '@configs/utils/helpers';
import React from 'react';
import './style.scss';

const Status = ({ data, filtersConfig, setFilterConfig }) => {
  const handleFilter = (target, slug) => {
    toggleActiveFilter(target);
    setFilterConfig(updateFilters(filtersConfig, 'status', slug));
  }

  return (
    <div className="wrapper-item-filter">
      <div className="list-filter">
        <div className="status">
          {data.childs.map((item, index) => {
            return (
              <span className="btn-status" key={index} onClick={(e) => {
                handleFilter(e.target, item.slug)
              }}>
                {item.name}
                <span> ({item.quantity})</span>
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Status;
