import React from 'react';

const Section = ({ className='', children, padding='', container, paddingMb='' }) => {
  const containerClass = (container === 'small' ? 'container-content' : 'container');
  const paddingClass = padding === 'no' ? ' --no-padding' : '';
  const paddingMbClass= paddingMb === 'no' ? ' --no-padding-mb' : '';
  const classSection = containerClass + paddingClass + paddingMbClass;

  return (
    <div className={'section' + ` ${className}`}>
      <div className={classSection}>
        {children}
      </div>
    </div>
  );
};

export default Section;
