import React from 'react';

const ThumbProduct = ({item, setThumb}) => {
  return (
    <div className="thumb-wrapper">
      {item.listColors.map((item1, index1) => {
        return index1 <= 4 ? (
          <span
            onMouseEnter={() => {
              setThumb(item1.productImgColor);
            }}
            onMouseLeave={() => {
              setThumb('');
            }}
            className="small-images"
            key={index1}>
            <img src={item1.productImgColor} />
          </span>
        ) : (
          ''
        );
      })}

      <span className="number-images">
        {item.listColors.length > 5 ? '+' + (item.listColors.length - 5) : ''}
      </span>
    </div>
  );
};

export default ThumbProduct;
