import Icon from '@common/Icon';
import { Dropdown } from 'antd';
import React, { useState } from 'react';

const listFilter = [
  { label: <div href="/about">Bán Chạy</div> },
  { label: <div href="/about">Mới Nhất</div> },
  { label: <div href="/about">Cũ Nhất</div> },
  { label: <div href="/about">Giá Tăng Dần</div> },
  { label: <div href="/about">Giá Giảm Dần</div> }
];

const SortProduct = ({ showFilter, setShowFilter }) => {
  const [activeDropdown, setActiveDropdown] = useState(false);
  
  return (
    <div className="wall-header">
      <div className="toggle-filters">
        <span className="text">Ẩn bộ lọc</span>
        <Icon className="icon-filters" icon="filters" onClick={() => {
          setShowFilter(!showFilter)
        }} />
      </div>
      <div className="sorter-label">
        <span className="number-product">153 Sản Phẩm</span>
        <Dropdown
          className="dropdown-select"
          overlayClassName="dropdown-select"
          placement="bottomRight"
          menu={{ items: listFilter }}
          trigger={['click']}
          onOpenChange={(open) => {
            setActiveDropdown(open);
          }}
        >
          <div className="sorter">
            <span>Sắp Xếp</span>
            <Icon
              className="arrange"
              icon={activeDropdown ? 'arrow-up-bold' : 'arrow-down-bold'}
              size={24}
              color="black"
            />
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

export default SortProduct;
