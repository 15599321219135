import React from 'react';

const TitleCollapse = ({title = ''}) => {
  return (
    <div>
      <div className="title">{title}</div>
    </div>
  );
};

export default TitleCollapse;
