import React, { useEffect, useState } from "react";
import "./style.scss";
import Image1 from "@images/process/home.svg";
import Image2 from "@images/process/gifts.svg";
import Image3 from "@images/process/lookup.svg";
import { Link } from "react-router-dom";
import Section from "../../common/Section";

const ProcessWithIcon = (props, data) => {
  const [columnClass, setColumnClass] = useState("col-sm-4");
  const dataTmp = [
    {
      image: Image1,
      title: "free shipping",
      content: "free shipping với hoá đơn từ 800k!",
    },
    {
      image: Image2,
      title: "chính sách đổi trả",
      content: "Hàng 2 tuần nhận đổi",
    },
    {
      image: Image3,
      title: "Tra cứu",
      content: "Kiểm tra trạng thái đặt hàng bất cứ lúc nào đặt hàng bất cứ lúc nàođặt hàng bất cứ lúc nào",
    },

  ];

  useEffect(() => {
    if (props.column === "2") {
      setColumnClass("col-sm-6");
    } else if (props.column === "3") {
      setColumnClass("col-sm-4");
    } else if (props.column === "4") {
      setColumnClass("col-sm-3");
    } else {
      setColumnClass("col-sm-3");
    }
  }, []);

  return (
    <Section>
      <div className="process">
        <div className="row justify-content-center">
          {dataTmp.map((item, index) => {
            return (
              <div className={columnClass} key={index}>
                <div className="process-grid">
                  <div className="images">
                    <img src={item.image} />
                  </div>
                  <div className="title">{item.title}</div>
                  <div className="content">{item.content}</div>
                  <div className="btn-wrap">
                    <Link className="btn-primary --border-line" to={item.link}>
                      Khám phá ngay
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default ProcessWithIcon;
