import React from 'react';
import ImageNoProduct from '@images/minicart/img-no-product.png';

const NoProduct = () => {
  return (
    <div className="no-product">
      <div className="image">
        <img src={ImageNoProduct} />
      </div>
      <div className="title">Chưa Có Gì Hết</div>
      <div className="content">Đừng đùa nữa, thêm sản phẩm vào giỏ ngay thôi nào</div>
    </div>
  );
};

export default NoProduct;
