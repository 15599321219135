import * as CONST_URL from './constants/urls'
import * as CONST_COLORS from './constants/colors';
import * as ACTION_APP from './actions/app';
import * as ACTION_USER from './actions/user';

export const ANANAS = {
  URL         : CONST_URL,
  ACTION      : {...ACTION_APP, ...ACTION_USER},
  COLORS      : {...CONST_COLORS.COLORS},
  API_URL     : process.env.API_URL
};
