import React from 'react';
import '@styles/components/not-found.scss';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => {
  return (
    <div className="not-found-page">
      <Helmet>
        <title>404 - Ananas</title>
      </Helmet>

      <div className="container container-star">
        {
          [...Array(30).keys()].map((item) => {
            return <div className="star-1" key={item}></div>;
          })
        }
        {
          [...Array(30).keys()].map((item) => {
            return <div className="star-2" key={item}></div>;
          })
        }
      </div>
      <div className="container container-bird">
        {
          [...Array(6).keys()].map((item) => {
            return (
              <div className="bird bird-anim" key={item}>
                <div className="bird-container">
                  <div className="wing wing-left">
                    <div className="wing-left-top"></div>
                  </div>
                  <div className="wing wing-right">
                    <div className="wing-right-top"></div>
                  </div>
                </div>
              </div>
            );
          })
        }
        <div className="container-title">
          <div className="title">
            <div className="number">4</div>
            <div className="moon">
              <div className="face">
                <div className="mouth"></div>
                <div className="eyes">
                  <div className="eye-left"></div>
                  <div className="eye-right"></div>
                </div>
              </div>
            </div>
            <div className="number">4</div>
          </div>
          <div className="subtitle">Chúng tôi rất tiết đã có lỗi gì đó xảy ra. Bạn vui lòng truy cập Trang Chủ hoặc liên hệ hotline 012345678 để được trợ giúp.</div>
          <button><a href="/">Trang chủ</a></button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
