import { ANANAS } from '@configs/index';
import React from 'react';
import { Link } from 'react-router-dom';

const NameProduct = ({ item }) => {
  return (
    <Link to={`${ANANAS.URL.PRODUCT}/${item.id}`}>
      <div className="product-name">{item.productName}</div>
    </Link>
  )
}

export default NameProduct;
