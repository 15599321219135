import React from 'react';
import Icon from '@icon';
import menuData from '@data/menu.json';
import { Link } from 'react-router-dom';
import Image1 from '@images/menu/image-branding.png';

const SiderbarMenu = ({ menuActive, setMenuActive, setIsActive }) => {
  return (
    <div className="left-siderbar-menu">
      <div className="nav-sidebar">
        <ul>
          {menuData.data.map((item, index) => {
            return (
              <li className={(menuActive === index) ? "active" : ""} key={index} onClick={() => {setMenuActive(index)}}>
                {item.link != '' ? (
                  <Link to={item.link} onClick={() => {
                    setIsActive(false)
                  }}>
                    <div className="nav-item">
                      <span className="number">{item.id}</span>
                      <span className="title">{item.category}</span>
                    </div>
                    {item.icon ? (
                      <Icon className="arrow-right" icon={item.icon} />
                    ) : (
                      ''
                    )}
                  </Link>
                ) : (
                  <>
                    <div className="nav-item">
                      <span className="number">{item.id}</span>
                      <span className="title">{item.category}</span>
                    </div>
                    {item.icon ? (
                      <Icon className="arrow-right" icon={item.icon} />
                    ) : (
                      ''
                    )}
                  </>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="image-branding">
        <img src={Image1} />
      </div>
    </div>
  );
};

export default SiderbarMenu;
