import React from 'react';

import dataDetail from '@data/productDetail.json';
import {priceSplitter} from '@configs/utils/helpers';

const Price = () => {
  const dataProductDetail = dataDetail.data;

  return (
    <div className="price">
      <span className="discount">
        {priceSplitter(dataProductDetail.priceWithPromotion)}
        <span className="currency-unit">đ</span>
      </span>
      <span className="old-price">
        {priceSplitter(dataProductDetail.price)}
        <span className="currency-unit">đ</span>
      </span>
      <span className="sale-off">{dataProductDetail.saleOff}</span>
    </div>
  );
};

export default Price;
