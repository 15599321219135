import React from 'react';
import Slider from 'react-slick';
import './style.scss';
import bannerData from '@data/slider.json';
import { Link } from 'react-router-dom';
import Section from '../../common/Section';

const SliderDefault = () => {
  let dragging = false;

  const settings = {
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    accessibility: true,
    focusOnSelect: false,
    touchEnabled: false,
    beforeChange: () => (dragging = true),
    afterChange: () => (dragging = false)
  };

  return (
    <Section padding="no">
      <div className="slider-banner">
        <Slider {...settings}>
          {bannerData.data.map((item, index) => {
            return (
              <div className="slider-item" key={index}>
                <Link
                  to={item.link}
                  onClick={(e) => dragging && e.preventDefault()}>
                  <picture>
                    <source media="(min-width:992px)" srcSet={item.image} />
                    <img src={item.image} />
                  </picture>
                </Link>
              </div>
            );
          })}
        </Slider>
      </div>
    </Section>
  );
};

export default SliderDefault;
