import React from 'react';

import dataDetail from '@data/productDetail.json';
import Icon from '@common/Icon';

const AvailableStore = () => {
  const dataProductDetail = dataDetail.data;

  return (
    <div className="available-store">
      <div className="title">
        <Icon className="icon-shop" icon="shop" size={24} />
        <span className="text">Tìm cửa hàng còn size?</span>
        <Icon className="icon-info" icon="info" size={24} />
      </div>
      <div className="content">{dataProductDetail.storeInformationContent}</div>
    </div>
  );
};

export default AvailableStore;
