import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import Image1 from '@images/banner/poster1.png';
import Image2 from "@images/banner/poster2.png";
import Section from '../../common/Section';

const Banner = (props) => {
  const [bannerClass, setBannerClass] = useState('');

  useEffect(() => {
    switch (props.type) {
      case '1':
        setBannerClass('banner-1');
        break;
      case '2':
        setBannerClass('banner-2');
        break;
      default:
        setBannerClass('banner-1');
        break;
    }
  }, []);

  return (
    <Section>
      <Link to="/about">
        <div className={'banner-link ' + bannerClass}>
          <picture>
            <source media="(min-width:992px)" srcSet={Image2} />
            <img className="image-banner" src={props.image || Image1} />
          </picture>
        </div>
      </Link>
    </Section>
  );
};

export default Banner;
