import React from 'react';

const TagsProduct = ({ item }) => {
  return (
    <div className="product-tags">
      {item.productTags.map((itemTag, indexTag) => {
        return <span key={indexTag}>{itemTag}</span>;
      })}
    </div>
  )
}

export default TagsProduct;
