
import { RESET_APP_STATE, UPDATE_LANGUAGE } from "../actions/app";
import messages_vi from "../translations/vi.json";
import messages_en from "../translations/en.json";

const init_app_info = {
  "lang"           : "vi",
  "messages"       : messages_vi,
}

export const app_info = (state = init_app_info, action) => {
  switch (action.type) {
    case UPDATE_LANGUAGE:
      return Object.assign({}, state, {
        lang : action.data.lang,
        messages : action.data.lang === "en" ? messages_en : messages_vi
      })
    case RESET_APP_STATE:
      return init_app_info;
    default:
      return state
  }
}
