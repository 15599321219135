import Icon from '@common/Icon';
import {toggleActiveFilter, updateFilters} from '@configs/utils/helpers';
import {Tooltip} from 'antd';
import React from 'react';
import './style.scss';

import {ANANAS} from '@configs/index';

const Color = ({data, filtersConfig, setFilterConfig}) => {
  const handleFilter = (target, slug) => {
    toggleActiveFilter(target);
    setFilterConfig(updateFilters(filtersConfig, 'collections', slug));
  };

  return (
    <div className="wrapper-item-filter">
      <div className="list-filter">
        <div className="colors">
          {data.childs.map((item, index) => {
            return (
              (item.name || item.color) && (
                <span key={index}
                  className="item-color"
                  onClick={(e) => {
                    e.preventDefault();
                    handleFilter(e.target.parentElement, item.slug);
                  }}>
                  <Tooltip
                    placement="bottomLeft"
                    title={item.tooltipColor}
                    color={ANANAS.COLORS.white}
                    arrow={false}
                    key={index}>
                    <div style={item.color && !item.name ? {backgroundColor: item.color} : {}}>
                      {item.name && <img src={item.name} />}
                    </div>
                  </Tooltip>
                  <div className="icon-check">
                    <Icon icon="check" size={24} />
                  </div>
                </span>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Color;
