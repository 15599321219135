import {priceSplitter} from '@configs/utils/helpers';
import React from 'react';

const PriceProduct = ({item}) => {
  return (
    <div className="product-price-wrapper">
      {item.priceWithPromotion > 0 ? (
        <div className="discount-products">
          <span className="discount">
            {priceSplitter(item.priceWithPromotion)} <span className="currency-unit">đ</span>
          </span>
          <span className="old-price">
            {priceSplitter(item.price)} <span className="currency-unit">đ</span>
          </span>
          <span className="sale-off">-10% Off</span>
        </div>
      ) : (
        <div className="product-price">{priceSplitter(item.price)} <span className="currency-unit">đ</span></div>
      )}
    </div>
  );
};

export default PriceProduct;
