import React from 'react';

import dataDetail from '@data/productDetail.json';
import Icon from '@common/Icon';
import PopupSizeChart from '@components/ProductList/PopupSizeChart';
import DetailedInformation from './DetailedInformation';
import Size from './Size';
import Price from './Price';
import ThumbWrapper from './ThumbWrapper';
import AvailableStore from './AvailableStore';
import ReviewStar from './ReviewStar';

const RightDetail = () => {
  const dataProductDetail = dataDetail.data;

  return (
    <div className="col-sm-4 right-detail">
      <div className="product-tags">{dataProductDetail.productTags}</div>
      <div className="name-product">
        <div className="name">
          {dataProductDetail.titleProduct}
          <span className="product-badges">{dataProductDetail.tagSale}</span>
        </div>
      </div>
      <div className="color">{dataProductDetail.productColor}</div>
      <ReviewStar />
      <Price />
      <ThumbWrapper />
      <PopupSizeChart />
      <Size />
      <div className="btn-primary add-to-cart">
        Thêm vào giỏ hàng
        <Icon className="icon-cart" icon="cart" size={24} />
      </div>
      <div className="btn-primary favorite">
        Yêu thích
        <Icon className="icon-heart" icon="heart" size={24} />
      </div>
      <AvailableStore />
      <DetailedInformation />
    </div>
  );
};

export default RightDetail;
