import React from 'react';
import './poster.scss';

import poster1 from '@images/product-list/poster-1.png';

const Poster = () => {
  return (
    <article className="product-card poster">
      <img className="image-poster" src={poster1} />
    </article>
  );
};

export default Poster;
