export const mapCTPStateToProps = state => {
  return ({ ananas_state  : state });
};

// helper: load state from local storage
export const loadStateFromLocalStorage = () => {
  try {
    const serializedState = '{"user_info":{"name":"","phone":"","email":"","carts":[],"products":[],"favorites":[1]},"app_info":{"lang":"vi","messages":{"header.title":"Header VietNam","header.searchText":"Tìm kiếm","footer.title":"Footer VietNam","footer.lang":"Việt Nam"}}}';
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState)
  } catch(err) {
    return undefined;
  }
};

// helper: save state to local storage
export const saveStateToLocalStorage = state => {
  try {
    const dataToStore = {
      user_info: state.user_info,
      app_info: state.app_info,
    };

    localStorage.setItem('ananas_data', JSON.stringify(dataToStore));
  } catch {
    console.log('error');
  }
};

export const images = (name) => {
  return `./images/${name}`;
}

export const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));

export const freeze = (status) => {
  status ?
    document.body.classList.add("freeze") :
    document.body.classList.remove("freeze");
}

export const arrModSizeChart = (data, numColumn) => {
  const numMod = data.length % numColumn ? numColumn - (data.length % numColumn) : 0;
  const arrMod = [...Array(numMod).keys()];
  return arrMod;
}

export const updateFilters = (filtersConfig, filterType, slug) => {
  let tmpFilterConfig = filtersConfig;

  let arrFilters = tmpFilterConfig[filterType];
  const indexItemFilter = arrFilters.indexOf(slug);

  if (indexItemFilter !== -1) {
    arrFilters.splice(indexItemFilter);
  } else {
    arrFilters.push(slug);
  }

  tmpFilterConfig[filterType] = arrFilters;
  return {...tmpFilterConfig}
}

export const toggleActiveFilter = (target) => {
  if (target.classList.contains('active')) {
    target.classList.remove('active');
  } else {
    target.classList.add('active');
  }
}

export const getWidthStar = (point) => {
  const newPoint = point + 0.05;
  const starWidthPadding = 124;
  const starWidthNotPadding = 100;
  const gapSize = (starWidthPadding - starWidthNotPadding) / 5.0;
  const starWidth = Math.round((newPoint * (starWidthNotPadding / 5.0)) + (newPoint * gapSize));
  return starWidth;
}
